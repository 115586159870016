var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('header',[_c('div',{staticClass:"content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.baseData.logo),expression:"baseData.logo"}],staticClass:"logo",on:{"click":function () {
            _vm.$router.push('/index');
          }}},[_c('img',{attrs:{"src":_vm.baseData.logo,"alt":""}})]),_c('div',{staticClass:"logo_title",on:{"click":function () {
            _vm.$router.push('/index');
          }}},[_vm._v(" "+_vm._s(_vm.baseData.systemTitle)+" ")])])]),_c('section',{staticClass:"login_body"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"right_wrapper"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"activeKey":_vm.customActiveKey,"tabBarStyle":{ textAlign: 'center', borderBottom: 'unset' }},on:{"change":_vm.handleTabClick}},[_c('a-tab-pane',{key:"tab1",attrs:{"tab":"账号登陆"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'username',
                    {
                      rules: [{ required: true, message: '请输入手机号' }],
                      validateTrigger: 'change'
                    }
                  ]),expression:"[\n                    'username',\n                    {\n                      rules: [{ required: true, message: '请输入手机号' }],\n                      validateTrigger: 'change'\n                    }\n                  ]"}],attrs:{"size":"large","type":"text","placeholder":"手机号码/用户名"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    {
                      rules: [{ required: true, message: '请输入密码' }],
                      validateTrigger: 'blur'
                    }
                  ]),expression:"[\n                    'password',\n                    {\n                      rules: [{ required: true, message: '请输入密码' }],\n                      validateTrigger: 'blur'\n                    }\n                  ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"密码"}})],1)],1),_c('a-tab-pane',{key:"tab2",attrs:{"tab":"短信登录"}},[_c('a-form-item',[_c('a-input-group',{attrs:{"compact":""}},[_c('a-input',{staticStyle:{"width":"64px","height":"40px"},attrs:{"default-value":"+86"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'mobile',
                      {
                        rules: [
                          {
                            required: true,
                            pattern: /^1[3456789]\d{9}$/,
                            message: '请输入正确的手机号'
                          }
                        ],
                        validateTrigger: 'change'
                      }
                    ]),expression:"[\n                      'mobile',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            pattern: /^1[3456789]\\d{9}$/,\n                            message: '请输入正确的手机号'\n                          }\n                        ],\n                        validateTrigger: 'change'\n                      }\n                    ]"}],staticStyle:{"width":"267px","height":"40px"},attrs:{"size":"large","type":"text","placeholder":"手机号码"}})],1)],1),_c('a-form-item',[_c('a-input-group',{attrs:{"compact":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'code',
                      {
                        rules: [{ required: true, message: '请输入验证码' }],
                        validateTrigger: 'blur'
                      }
                    ]),expression:"[\n                      'code',\n                      {\n                        rules: [{ required: true, message: '请输入验证码' }],\n                        validateTrigger: 'blur'\n                      }\n                    ]"}],staticStyle:{"width":"221px","height":"40px"},attrs:{"size":"large","type":"text","placeholder":"请输入验证码"}}),_c('a-button',{staticStyle:{"width":"110px","height":"40px","color":"rgba(251,52,52,1)","textAlign":"center","fontWeight":"bold"},attrs:{"tabindex":"-1","disabled":_vm.state.smsSendBtn},domProps:{"textContent":_vm._s(
                      (!_vm.state.smsSendBtn && '获取验证码') || _vm.state.time + ' s'
                    )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getCode.apply(null, arguments)}}})],1)],1)],1)],1),_c('a-form-item',{staticStyle:{"margin-top":"16px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("登录")])],1),_c('div',{staticClass:"actions_item"},[_c('span',{staticStyle:{"color":"rgba(0,0,0,0.45)","cursor":"pointer"},on:{"click":_vm.goRegister}},[_vm._v("立即注册")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.customActiveKey == 'tab1'),expression:"customActiveKey == 'tab1'"}]},[_vm._v("|")]),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.customActiveKey == 'tab1'),expression:"customActiveKey == 'tab1'"}],staticStyle:{"color":"rgba(0,0,0,0.45)"},attrs:{"to":{ name: 'rePwd' }}},[_vm._v("忘记密码")]),_c('span',[_c('a',{attrs:{"target":"#","href":"https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97817c5390744c9abd4ae0e0344549e2a29e6fdc2fdf2b2137a1eaebae5c0f1ad81b0ab6a09db04a4b9520996e25ff7c"}},[_vm._v("联系客服")])])],1)],1)],1)])]),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left_wrapper"},[_c('div',{staticClass:"title"},[_c('div',[_vm._v("分站开通")]),_c('div',[_vm._v("拥有属于自己的网站")])]),_c('div',{staticClass:"sub_title"},[_vm._v(" 独立后台自由管理 · 24小时自动售单 · 无需人工干预 ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',[_c('div',[_vm._v("© 2020 速代发 , All Rights Reserved 粤ICP备20038572")])])}]

export { render, staticRenderFns }